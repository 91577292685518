import {createStore} from 'vuex'

const store = createStore({
  state () {
    return {
      maintaining: false,
      notifications: {},
      imageGenerationOptions: {
        model: 'dall-e-2',
        size: '1024x1024'
      },
      updatingGridImageList: false
    }
  },
  mutations: {
    pushNotification(state, notification) {
      let id = Date.now()
      state.notifications[id] = notification
    },
    changeImageGenerationOptions(state, options) {
      state.imageGenerationOptions = options
    }
  }
})

export {
  store
}
