import { createApp } from 'vue'
import './style/style.scss'
import App from './App.vue'
import '@picocss/pico'
import './style/pico.custom.css'

import router from './routes/main.js'
import {store} from './utils/store.js'
import {enableDynamicDarkModeInPwa} from './utils/defaults.js'

let app = createApp(App)

app.use(router)
app.use(store)

app.mount('#app')

enableDynamicDarkModeInPwa()
