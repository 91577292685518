<template>
  <div class="notifications">
    <div
      :id="'n_' + item.id"
      :key="item.id"
      class="item absolute w-full right-4 bg-white/50 dark:bg-neutral-600/50"
      v-for="(item, index) in _n"
      @mouseenter="item.focus = true"
      @mouseleave="item.focus = false"
      style="transition: top .3s ease; top: 0; backdrop-filter: blur(6px); -webkit-backdrop-filter: blur(6px);"
      :style="{
        top: calcTop(index, item.id) + 'px',
        pointerEvents: item.interactive ? 'auto' : 'none'
      }"
      :class="{
      'in': item.shouldEnter,
      'out': item.shouldLeave,
      'flat': item.shouldCollapse,
      'info': item.type === 'info',
      'success': item.type === 'success',
      'error': item.type === 'error'
    }">

      <div class="flex items-center">
        <div class="mr-2">
          <i v-if="item.type === 'info'" class="iconfont icon-prompt" style="font-size: 1.5rem"></i>
          <i v-if="item.type === 'success'" class="iconfont icon-success text-green-500" style="font-size: 1.5rem"></i>
          <i v-if="item.type === 'error'" class="iconfont icon-error text-red-500" style="font-size: 1.5rem"></i>
        </div>
        <div class="text-xs break-words break-all" v-html="item.text">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed} from 'vue'
import {store} from '../utils/store.js'

let _n = computed(() => {
  let keys = Object.keys(store.state.notifications)
  let arr = []
  keys.forEach(k => {
    let obj = store.state.notifications[k]
    arr.push(obj)
  })
  return arr
})

let calcTop = function(index, id) {
  let top = 16

  for (let i = 0; i < index; i++) {
    let el = document.getElementById('n_' + _n.value[i].id)
    top += el.offsetHeight + 16
  }

  return top
}
</script>

<style scoped>

</style>
