import {openDB, deleteDB, wrap, unwrap} from 'idb'

const db = await openDB('zy_darkroom_db', 1, {
  upgrade(db) {
    db.createObjectStore('image_store')
  }
})

function IDB(storeName) {
  this.put = async (key, value) => {
    await db.put(storeName, value, key)
  }

  // same as put, alias
  this.set = async (key, value) => {
    await db.put(storeName, value, key)
  }

  this.get = async (key) => {
    return await db.get(storeName, key)
  }

  this.delete = async (key) => {
    await db.delete(storeName, key)
  }

  this.keys = async () => {
    return await db.getAllKeys(storeName)
  }

  this.clear = async () => {
    await db.clear(storeName)
  }
}

export default IDB
