<template>
  <div class="px-4">
    <div class="mb-4">
      <label for="" class="text-xs opacity-80 mb-2 block">选择图片生成模型</label>
      <select v-model="model" class="text-sm mb-0 reflective-border">
        <option :value="item.value" v-for="item in modelOptions">{{ item.label }}</option>
      </select>
    </div>
    <div class="mb-4">
      <label for="" class="text-xs opacity-80 mb-2 block">
        选择图片尺寸 ({{ size }})
      </label>
      <div class="flex justify-center items-center py-4">
        <button v-for="item in sizeOptionsFilter(model)" @click="size = item.value"
                class="mb-2 mx-2 border-none flex justify-center items-center px-0 py-0 bg-transparent min-h-[2rem] w-auto rounded-none min-w-[2rem]">
          <span class="block border-2" :style="{
          width: item.valueInArray[0] / 1024 * 2 + 'rem',
          height: item.valueInArray[1] / 1024 * 2 + 'rem'
        }" :class="{
            'border-neutral-900 dark:border-neutral-100': size === item.value,
            'border-neutral-400 dark:border-neutral-500': size !== item.value
        }"></span>
        </button>
      </div>
    </div>
    <div class="">
      <label for="" class="text-xs opacity-80 mb-2 block">
        一次生成将消耗
      </label>
      <div class="text-sm">
        <span class="font-black font-mono">{{ price }}</span> 点数
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {calcImagePrice} from '../utils/price.js'
import {findLocalAccessToken} from '../utils/defaults.js'

let store = useStore()

onMounted(function () {
  readUserConfigFromLocal()
})

let model = ref('dall-e-2')
let size = ref('1024x1024')
let price = ref(0)

let preventWatch = ref(true)

async function calcPrice() {
  let token = findLocalAccessToken()

  if(!token){
    await new Promise(resolve => setTimeout(resolve, 300))
    await calcPrice()
    return
  }

  price.value = await calcImagePrice(size.value, model.value, token)
}

watch(model, function (val) {
  if (preventWatch.value) {
    return
  }
  size.value = '1024x1024'
  saveUserConfig()
  calcPrice()
})

watch(size, function () {
  if (preventWatch.value) {
    return
  }
  saveUserConfig()
  calcPrice()
})

function saveUserConfig() {
  let config = {
    model: model.value,
    size: size.value
  }
  localStorage.setItem('user_config', JSON.stringify(config))

  store.commit('changeImageGenerationOptions', config)
}

function sizeOptionsFilter(model) {
  return sizeOptions.filter(item => item.ableToBeAppliedModels.includes(model))
}

const modelOptions = [
  {
    label: 'DALL-E 2',
    value: 'dall-e-2'
  },
  {
    label: 'DALL-E 3',
    value: 'dall-e-3'
  },
  {
    label: 'DALL-E 3 HD',
    value: 'dall-e-3-hd'
  }
]

const sizeOptions = [
  {
    label: '1024 x 1024',
    value: '1024x1024',
    valueInArray: [1024, 1024],
    ableToBeAppliedModels: ['dall-e-2', 'dall-e-3', 'dall-e-3-hd']
  },
  {
    label: '512 x 512',
    value: '512x512',
    valueInArray: [512, 512],
    ableToBeAppliedModels: ['dall-e-2']
  },
  {
    label: '256 x 256',
    value: '256x256',
    valueInArray: [256, 256],
    ableToBeAppliedModels: ['dall-e-2']
  },
  {
    label: '1792 x 1024',
    value: '1792x1024',
    valueInArray: [1792, 1024],
    ableToBeAppliedModels: ['dall-e-3', 'dall-e-3-hd']
  },
  {
    label: '1024 x 1792',
    value: '1024x1792',
    valueInArray: [1024, 1792],
    ableToBeAppliedModels: ['dall-e-3', 'dall-e-3-hd']
  }
]

async function readUserConfigFromLocal() {
  let userConfig = localStorage.getItem('user_config')
  if (userConfig) {
    userConfig = JSON.parse(userConfig)
    model.value = userConfig.model
    size.value = userConfig.size

    store.commit('changeImageGenerationOptions', userConfig)
  }

  await calcPrice()

  setTimeout(function () {
    preventWatch.value = false
  }, 500)
}
</script>
