import {nanoid} from 'nanoid'
import {store} from './store.js'
import axios from 'axios'
import {USER_POOL_CLIENT_ID, USER_POOL_ID} from './globalVars.js'

/**
 * Check if the app is running in a PWA
 * @returns {boolean|boolean}
 */
export function checkIsPWA() {
  return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true
}

/**
 * Check if the app is running in a PWA
 * @returns {false|*}
 * @constructor
 */
export function PwaCheck() {
  return window.matchMedia('(display-mode: standalone)').matches && _.isMobile
}

/**
 * Check if the app is running in a mobile device
 * @returns {boolean}
 */
export function mobileCheck() {
  let check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export let getApiBase = function (env = '') {
  switch (env) {
    case 'cf':
      if (location.hostname === 'localhost') {
        return 'http://localhost:8011'
      } else {
        return 'https://cf-api.zhiyan.dev'
      }
      break
    default :
      if (location.hostname === 'localhost') {
        return 'http://localhost:8010'
      } else {
        return 'https://api.zhiyan.dev'
      }
      break
  }
}

export function trim(str) {
  return str.replace(/^\s+/, '')
}

export function notify(message) {
  let _msg = {
    text: '',
    duration: 3900,
    type: 'info', // info, success, error
    focus: false,
    shouldLeave: false,
    shouldEnter: true,
    shouldCollapse: false,
    id: nanoid(16),
    interactive: false
  }

  if (typeof message === 'string') {
    _msg.text = message
  }

  if (typeof message === 'object') {
    message.duration ? message.duration += 900 : 3900
    _msg = {
      ..._msg,
      ...message
    }
  }

  store.commit('pushNotification', _msg)

  let notifications = store.state.notifications
  let keys = Object.keys(notifications)
  let id = keys[keys.length - 1]

  // initiate enter animation
  setTimeout(() => {
    store.state.notifications[id].shouldEnter = false
  }, 300)

  _leave()

  function _leave() {
    let _step = 300
    // remove notification after duration
    setTimeout(() => {
      if (store.state.notifications[id].focus) {

        setTimeout(() => {
          _leave()
        }, 1000)

        return false
      }
      // initiate leave animation
      setTimeout(() => {
        store.state.notifications[id].shouldLeave = true
      }, _step)

      // initiate collapse animation
      setTimeout(() => {
        store.state.notifications[id].shouldCollapse = true
      }, _step * 2)

      setTimeout(() => {
        delete store.state.notifications[id]
      }, _step * 2)

    }, store.state.notifications[id].duration - _step * 2)
  }
}

/**
 * Find access token in local storage
 * @returns {null|string}
 */
export function findLocalAccessToken() {
  let localKeys = Object.keys(localStorage)
  let token = null

  localKeys.forEach(key => {
    if (!key.startsWith('CognitoIdentityServiceProvider')) {
      return false
    }

    let keyParts = key.split('.')

    if (keyParts[3] && keyParts[3] !== 'accessToken') {
      return false
    }

    token = localStorage.getItem(key)
  })

  return token
}

/**
 * Find refresh token in local storage
 * @returns {null|string}
 */
export function findLocalRefreshToken() {
  let localKeys = Object.keys(localStorage)
  let token = null

  localKeys.forEach(key => {
    if (!key.startsWith('CognitoIdentityServiceProvider')) {
      return false
    }

    let keyParts = key.split('.')

    if (keyParts[3] && keyParts[3] !== 'refreshToken') {
      return false
    }

    token = localStorage.getItem(key)
  })

  return token
}

/**
 * Renew access token
 * @returns {Promise<{success: boolean, reason: *}>}
 */
export async function renewToken() {
  console.log('renewing token...')
  let refreshToken = findLocalRefreshToken()

  let res

  try {
    res = await axios({
      method: 'post',
      url: 'https://aws-cognito-helper.zhiyan.dev/renew',
      data: {
        refreshToken: refreshToken,
        userPool: USER_POOL_ID,
        clientId: USER_POOL_CLIENT_ID
      }
    })
  } catch (err) {
    console.log(err)
    return {
      success: false,
      reason: err
    }
  }

  if (!res.data.AuthenticationResult) {
    return {
      success: false,
      reason: res.data
    }
  }

  console.log('token renewed...')
  localStorage.setItem(`CognitoIdentityServiceProvider.${USER_POOL_CLIENT_ID}.${localStorage.getItem('username_from_sso')}.accessToken`, res.data.AuthenticationResult.AccessToken)
  localStorage.setItem(`CognitoIdentityServiceProvider.${USER_POOL_CLIENT_ID}.${localStorage.getItem('username_from_sso')}.idToken`, res.data.AuthenticationResult.IdToken)
  return {
    success: true
  }
}

export function enableDynamicDarkModeInPwa() {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // dark mode
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#121212')
  } else {
    // light mode
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffffff')
  }


  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    const newColorScheme = event.matches ? 'dark' : 'light'
    console.log(newColorScheme)

    document.querySelector('meta[name="theme-color"]').setAttribute('content', newColorScheme === 'dark' ? '#121212' : '#ffffff')
  })
}
