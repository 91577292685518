import auth from './auth.js'

let modelPriceMap = {
  'dall-e-2': {
    '1024x1024': 0.02,
    '512x512': 0.018,
    '256x256': 0.016
  },
  'dall-e-3': {
    '1024x1024': 0.04,
    '1024x1792': 0.08,
    '1792x1024': 0.08
  },
  'dall-e-3-hd': {
    '1024x1024': 0.08,
    '1024x1792': 0.12,
    '1792x1024': 0.12
  }
}

export async function calcImagePrice(size, model, token) {
  let ratio = 1.44

  if(!token){
    return 0
  }

  let user = await auth(token)

  if (user['cognito:groups'].includes('只言「会员」')) {
    ratio = 1.01
  }

  let price = modelPriceMap[model][size]
  let oneKTokenCost = 0.002
  let Ks = price / oneKTokenCost

  return Ks * 1000 * ratio
}
