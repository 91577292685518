import {createLocalJWKSet, jwtVerify, decodeJwt} from 'jose'

const pub_key = {
  'keys': [{
    'alg': 'RS256',
    'e': 'AQAB',
    'kid': 'diZp9Ki7Z2wLkJQimTEBKILuaM1TXq3yglilHYhVnnk=',
    'kty': 'RSA',
    'n': '4ZRKUqdL-sCk3cggp-kQIe-2YlE7Spd7F06r12-fLmt8lOEhIarrRhrHfn0U3z696q_bweIWPGtxFxfcuZNg5yNbIt7649I5RAgRbc5Bi5551XOuZ7L5VBlvtEkKq9AOBBb44hOuBlX3r3lNt62J6-c2epmaTBG0UAuP8GZlk_RFJ64F9UvoZex28Egj-n-AWA1jEBdZN1eaavYafPbmSs0IxrZOQ9L8LoPTFJPvcxIkaUeThcWYxMGHCGwXovbGp0FbpLdXz4CoTm89d6VF6smtYzWCtmNj6BheDltnG-i3-gqk5jtXPYRezIRvz14vtG27RuUzQ-y_yLZKJnVH-w',
    'use': 'sig'
  }, {
    'alg': 'RS256',
    'e': 'AQAB',
    'kid': 'UVg/unPuyVfH7xw/ngYk//oJ7iKO5PNqPixdEOD9ERM=',
    'kty': 'RSA',
    'n': 'wx_U2t9G8A0Pf8v9QW_3bY7VZrWPxDp01mPU4vaPRIZdsZ3OSL34plxImcTyB42ZEVe6qle7OCzgPxGWrRUbp3dzWzQ97IDRRC2UN4HG7248bKz8kUbjCeVN-kp8rFGkTVJiIR-aNFlLG67DGtuvvB1errCoZPR-KaCd_wzFLHPcbjTje514o4zJbfn0FavUPi8Ll6w5RUri8JOGZWaG8YUTcJ9XTAJ-GOEqp61mYUVqZ5_m8jiVs5UdTmNjPMlXziUmAgcQ0i3SRDcu6lU7FwkP-Rfhvlrp5PzqC7a12tQtL_V2S0XG0qchPKvDI-Fd24aA3zKyWP1SHwMTd4dfgw',
    'use': 'sig'
  }]
}

let jose_pub_key = createLocalJWKSet(pub_key)

/**
 * Verify JWT
 * @param jwt
 * @returns {Promise<{valid: boolean, reason: string}|{valid: boolean, reason: string, 'cognito:groups': unknown, Username: unknown}>}
 */
export default async function auth(jwt) {
  let isValidJWT = false
  let reason = ''

  if(!jwt){
    return {
      valid: false,
      reason: 'No JWT provided'
    }
  }

  let decoded = decodeJwt(jwt)
  try {
    await jwtVerify(jwt, jose_pub_key)
    isValidJWT = true
  } catch (e) {
    console.log(e)
    reason = e.code
  }

  return {
    valid: isValidJWT,
    reason,
    Username: decoded.username,
    'cognito:groups': decoded['cognito:groups']
  }
}
