<template>
  <button v-show="currentRouteName !== 'Login'" class="fixed w-[36px] h-[36px] dark:bg-white rounded-full top-[20px] left-[20px] bg-[#121212] z-[3050]"
          @click="showPageOptions = !showPageOptions" @mouseenter="mouseIsInAside = true"
          @mouseleave="mouseIsInAside = false">
    <i class="iconfont icon-close-bold left-[50%] top-[50%] opacity-0 scale-100 absolute"
       style="transition: all .2s ease; transform: translate(-50%, -50%)" :style="{
          opacity: showPageOptions ? 1 : 0,
          transform: showPageOptions ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(1.7)'
        }"></i>
    <span class="font-title inline-block absolute left-[50%] top-[50%] opacity-0 scale-50"
         style="transform: translate(-50%, -50%); transition: all .2s ease"
         :style="{
          opacity: !showPageOptions ? 1 : 0,
          transform: !showPageOptions ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0)'
        }">
      Dr.
    </span>
  </button>
  <aside
    v-show="currentRouteName !== 'Login'"
    class="noise-bg fixed left-[10px] z-[3050] top-[74px] p-4 max-w-[280px] min-w-[260px] border dark:border-neutral-700 rounded-[8px] dark:border-t-neutral-600 dark:border-l-neutral-600 transition-all border-neutral-200 border-l-neutral-100 border-t-neutral-100 shadow overflow-x-hidden overflow-y-auto bg-white/70 dark:bg-[#121212]/70 backdrop-blur-xl" :class="{
      'rounded-bl-[1rem]': mobilePWA
    }"
    @mouseenter="mouseIsInAside = true" @mouseleave="mouseIsInAside = false"
    style="width: calc(100% - 20px); height: calc(100vh - 84px)" :style="{
           left: showPageOptions ? '10px' : '-30px',
           opacity: showPageOptions ? 1 : 0,
           pointerEvents: showPageOptions ? 'auto' : 'none'
         }">

    <!--    logo-->
    <div class="py-4">
      <div class="text-center font-title text-xl">
        Darkroom
      </div>
      <div class="text-xs items-center justify-center flex">
        <div class="mr-1">
          Powered by
        </div>
        <img src="../img/logo.png" alt="只言 - Darkroom" style="height: 1.5rem" class="block dark:hidden">
        <img src="../img/logo-w.png" alt="只言 - Darkroom" style="height: 1.5rem" class="hidden dark:block">
      </div>
    </div>

    <div class="py-4" v-show="currentRouteName === 'Home'">
      <button
        class="outline border-none text-sm dark:bg-transparent dark:hover:bg-white/10 flex items-center h-[40px] rounded-[8px] mb-0 hover:bg-black/10"
        @click="goHistoryList">
        <span class="block mr-2 w-[20px]">
          <i class="iconfont icon-training"></i>
        </span>
        <span>历史记录</span>
      </button>
    </div>

    <div class="py-4" v-show="currentRouteName === 'GenerationHistory'">
      <button
        class="outline border-none text-sm dark:bg-transparent dark:hover:bg-white/10 flex items-center h-[40px] rounded-[8px] mb-0 hover:bg-black/10"
        @click="goHome">
        <span class="block mr-2 w-[20px] text-center">
          <span class="font-title text-sm">Dr.</span>
        </span>
        <span>生成图片</span>
      </button>
    </div>

    <hr class="mx-4 mb-4" v-show="currentRouteName === 'Home'">

    <image-generation-options v-if="currentRouteName === 'Home'" class="mb-4"></image-generation-options>

    <hr class="mx-4">


    <!--    button group-->
    <div class="py-4">
      <button
        class="outline border-none text-sm dark:bg-transparent dark:hover:bg-white/10 flex items-center h-[40px] rounded-[8px] mb-0 hover:bg-black/10"
        v-for="item in buttonGroup" :key="item.id" @click="item.trigger" :aria-busy="loadingButtonName === item.name" :disabled="loadingButtonName === item.name">
        <span class="block mr-2 w-[20px]">
          <i class="iconfont" :class="item.icon"></i>
        </span>
        <span>{{ item.name }}{{ item.external ? ' ↗' : '' }}</span>
      </button>
    </div>

    <footer class="text-xs text-center pb-[4rem] pt-[3rem]">
      © 2023 只言 - Darkroom<br>Code with ❤️
      <br><a href="https://t.me/zhiyan_gpt" target="_blank" class="text-blue-500">Telegram 群组</a> | <a
      href="mailto:jw@jw1.dev" class="text-blue-500">反馈</a>
    </footer>

  </aside>
</template>
<script setup>
import {onMounted, ref} from 'vue'
import axios from 'axios'
import {USER_POOL_CLIENT_ID, USER_POOL_ID} from '../utils/globalVars.js'
import {findLocalRefreshToken, notify, PwaCheck, mobileCheck} from '../utils/defaults.js'
import {useRouter} from 'vue-router'
import {nanoid} from 'nanoid'
import ImageGenerationOptions from './ImageGenerationOptions.vue'
import IDB from '../utils/idb.js'

const imageStore = new IDB('image_store')

let mobilePWA = ref(false)

onMounted(function(){
  mobilePWA.value = mobileCheck() && PwaCheck()
})

const router = useRouter()

let currentRouteName = ref('')
router.afterEach((to, from) => {
  currentRouteName.value = to.name
})

let mouseIsInAside = ref(false)
let showPageOptions = ref(false)

async function goHistoryList(){
  await router.push('/generation-history')
  await new Promise(resolve => setTimeout(resolve, 300))
  showPageOptions.value = false
}

async function goHome(){
  await router.push('/home')
  await new Promise(resolve => setTimeout(resolve, 300))
  showPageOptions.value = false
}

function goToUserCenter() {
  window.open('https://user.zhiyan.dev')
}

let loggingOut = ref(false)
let loadingButtonName = ref('')

function logout() {
  let c = confirm('确定要退出登录吗？退出后所有生成的图片将被清空且不可恢复！')

  if (!c) {
    return
  }

  loadingButtonName.value = '退出登录'

  axios({
    url: 'https://aws-cognito-helper.zhiyan.dev/revoke',
    method: 'post',
    data: {
      token: findLocalRefreshToken(),
      userPool: USER_POOL_ID,
      clientId: USER_POOL_CLIENT_ID
    }
  }).then(res => {
    localStorage.clear()
    imageStore.clear()

    loadingButtonName.value = ''

    router.push('/login')
  }).catch(err => {
    console.log(err)
    notify('登出失败，刷新一下网页看看？')
    loadingButtonName.value = ''
  })
}

window.addEventListener('click', function () {
  if (!mouseIsInAside.value) {
    showPageOptions.value = false
  }
})

let buttonGroup = ref([
  {
    id: nanoid(),
    name: '用户中心',
    external: true,
    trigger: goToUserCenter,
    icon: 'icon-user',
    loading: false
  },
  {
    id: nanoid(),
    name: '退出登录',
    external: false,
    trigger: logout,
    icon: 'icon-sign-out',
    loading: loggingOut.value
  }
])
</script>
